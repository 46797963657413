<template>
  <!-- footer -->
  <section class="footer-container" :class="color">
    <div class="footer-content w-page-or-full page-padding m-auto py-8">
      <div class="footer-grid flex">
        <!-- Title logo element -->
        <div class="inner-footer-item text-white cursor-pointer" @click="goto($routeNames.Home)">
          <img src="../../assets/images/logos/Lodylearn_Logo_white.svg" alt="Lodylearn" width="220"/>
          <div class="text-xl mt-2">Active Digital Learning</div>
        </div>
        <!-- end of title element -->
        <div class="inner-footer-item text-white text-xl">
          <ul class="m-auto mt-2">
            <router-link tag="li" v-for="link in links" :key="link.label" :to="link.route"
              class="cursor-pointer transition hover:underline mt-1">
              > {{ link.label }}
            </router-link>
          </ul>
        </div>
        <div class="inner-footer-item text-white flex-col">
          <div class="contact-block mt-2 text-sm">
            <div class="border-l-8 border-primary-500 pl-4 uppercase text-2xl">Contact</div>
            <div class="mt-2">20 rue Saint Gervais - 69008 Lyon</div>
            <div class="mt-1">info@lodylearn.fr</div>
            <div class="mt-1">04.87.61.93.64</div>
            <div class="mt-1 flex">
              <a v-for="rs in socials"
                 class="mr-3"
                 :href="rs.url" target="_blank" :key="rs.url">
                <img :src="rs.image" :alt="rs.url" width="24" height="24"/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-texts mt-4 text-sm">
        © Lodylearn {{ (new Date()).getUTCFullYear() }} •
        <button @click="openCgv" class="cursor-pointer transition underline hover:opacity-60">Conditions Générales de Ventes</button>
        •
        <button @click="openLegals" class="cursor-pointer transition underline hover:opacity-60"> Mentions légales</button>
        •
        <button @click="openPrivacy" class="cursor-pointer transition underline hover:opacity-60"> Politique de confidentialité</button>
        •
        <button @click="openHandicap" class="cursor-pointer transition underline hover:opacity-60"> Handicap</button>
      </div>
    </div>
  </section>
  <!-- end of footer -->
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { resolve } from '../../_helpers/image.helper';

const facebook = resolve('rs/Icone-RS_FB_fond.svg');
const instagram = resolve('rs/Icone-RS_Insta_fond.svg');
const linkedin = resolve('rs/Icone-RS_Linkedin_fond.svg');
const youtube = resolve('rs/Icone-RS_Youtube_fond.svg');

@Component({
  name: 'default-footer2',
  props: {
    color: {
      type: String,
      default: 'bg-primary-800',
    },
  },
  data() {
    return {
      socials: [
        // {
        //   image: facebook,
        //   url: 'https://facebook.com',
        // },
        // {
        //   image: instagram,
        //   url: 'https://instagram.com',
        // },
        // {
        //   image: linkedin,
        //   url: 'https://linkedin.com',
        // },
        // {
        //   image: youtube,
        //   url: 'https://youtube.com',
        // },
      ],
      links: [
        {
          label: 'Les modules',
          route: { name: this.$routeNames.Search },
        },
        {
          label: 'La plateforme',
          route: { name: this.$routeNames.Platform },
        },
        {
          label: 'Offre école/entreprise',
          route: { name: this.$routeNames.Offers },
        },
      ],
    };
  },
  computed: {
    year() {
      return (new Date()).getUTCFullYear();
    },
  },
  methods: {
    async goto(direction) {
      await this.$router.push({ name: direction });
    },
    openCgv() {
      this.$store.commit('globalModal/cgv/SET_OPEN', true);
    },
    openHandicap() {
      this.$store.commit('globalModal/handicap/SET_OPEN', true);
    },
    openLegals() {
      this.$store.commit('globalModal/legals/SET_OPEN', true);
    },
    openPrivacy() {
      this.$store.commit('globalModal/privacy/SET_OPEN', true);
    },
  },
})
export default class DefaultFooter extends Vue {
}
</script>

<style scoped>

</style>
